import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheService } from '@core/cache/cache.service';
import { Employee } from '@core/employee/employee.types';
import { MerchantService } from '@core/merchant/merchant.service';
import { Merchant } from '@core/merchant/merchant.types';
import { EnvironmentService } from '@core/utils/environment/environment.service';
import { DEFAULT_HTTP_OPTIONS_NO_EXPIRE, HttpOptions } from '@core/utils/http-options.types';
import { environment } from '@env/environment';
import { catchError, map, Observable, of, BehaviorSubject, switchMap, take, tap, throwError } from 'rxjs';


/**
 * Service for managing employees.
 */
@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  /**
   * The merchant associated with the employees.
   */
  private _merchant: Merchant | undefined;

  /**
   * The current employee.
   */
  private _employee: BehaviorSubject<Employee | null> = new BehaviorSubject<Employee | null>(null);

  /**
   * The list of employees.
   */
  private _employees: BehaviorSubject<Employee[] | null> = new BehaviorSubject<Employee[] | null>(null);
  
  /**
   * The cache namespace.
   */
  private _cacheNamespace: string = 'employee';

  /**
   * Creates an instance of CustomerService.
   * @param _environmentService - The environment service.
   * @param _httpClient - The HTTP client.
   * @param _merchantService - The merchant service.
   * @param _cacheService - The cache service.
   */
  constructor(
    private _environmentService: EnvironmentService,
    private _httpClient: HttpClient,
    private _merchantService: MerchantService,
    private _cacheService: CacheService
  ) {
    // Get the merchant
    this._merchantService.merchant$
      .subscribe((merchant: Merchant) => {
        const reload: boolean = this._merchant?._id?.toString() !== merchant?._id?.toString();
        this._merchant = merchant;
        if ( reload ) {
          this._employee.next(null);
          this._employees.next(null);
          this.getEmployees()
            .subscribe();
        }
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Returns an observable of the current list of employees.
   * @returns An observable of the current list of employees.
   */
  get employees$(): Observable<Employee[] | null> {
    return this._employees.asObservable();
  }

  /**
   * Returns an observable of the current employee.
   * @returns An observable of the current employee.
   */
  get employee$(): Observable<Employee | null> {
    return this._employee.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Retrieves an employee by ID.
   * @param employeeId The ID of the employee to retrieve.
   * @param options - The options related to cache and propagation.
   * @returns The requested employee object.
   */
  getEmployee(employeeId: string, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Employee | null> {
    // If there is no merchant, return null
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    // If the employee is already loaded, return it
    if (options.cache && this._cacheService.has(this._cacheNamespace, 'employee', { merchantId: this._merchant._id, employeeId })) {
      const employee: Employee = this._cacheService.get(this._cacheNamespace, 'employee', { merchantId: this._merchant._id, employeeId });
      if (options.forcePropagate) {
        this._employee.next(employee);
      }
      return of(employee);
    }
    // Otherwise, load the employee from the array of employees previously loaded
    return this._employees.pipe(
      take(1),
      map((employees: Employee[] | null) => {
        const employee = employees?.find(item => item._id === employeeId) || null;
        return employee;
      }),
      switchMap((employee: Employee | null) => {
        if ( !employee ) {
          console.error(`Error: Cannot find employee with id of ${employeeId}!`);
          return of(null);
        }
        // Handle the logic of the cache and the propagation
        if (options.cache) {
          this._cacheService.set(this._cacheNamespace, 'employee', { merchantId: this._merchant?._id, employeeId }, employee, options.expire);
        }
        if (options.propagate) {
          this._employee.next(employee);
        }
        return of(employee);
      })
    );
  }

  /**
   * Returns an array of employees for the current merchant.
   * @param options - The options related to cache and propagation.
   * @returns An array of employees.
   */
  getEmployees(options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Employee[]> {
    // If there is no merchant, return an empty array
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of([]);
    }
    // If the employees are already loaded, return them
    const cacheParams: any = { merchantId: this._merchant._id };
    if (options.cache && this._cacheService.has(this._cacheNamespace, 'employees', cacheParams)) {
      const employees: Employee[] = this._cacheService.get(this._cacheNamespace, 'employees', cacheParams);
      if (options.forcePropagate) {
        this._employees.next(employees);
      }
      return of(employees);
    }
    // Otherwise, load the employees from the backend
    return this._httpClient.get<Employee[]>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/employees`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
    .pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(`Error: ${error}`);
        return of([]);
      }),
      // Handle the logic of the cache and the propagation
      tap((employees: Employee[]) => {
        if (options.cache) {
          this._cacheService.set(this._cacheNamespace, 'employees', cacheParams, employees, options.expire);
        }
        if (options.propagate) {
          this._employees.next(employees);
        }
      })
    );
  }

}
